import React from "react";
import { Link } from "react-router-dom";
import HamburgerMenu from "./Hamburger";

const Header = () => {
  return (
    <>
      <nav className="navbar navbar-default navbar-fixed-top">
        <div className="flex">
          <HamburgerMenu />
        </div>
        <div className="container">
          <div className="navbar-header page-scroll">
            <Link to="/">
              <span className="navbar-brand page-scroll">RESOLUTION.PT</span>
            </Link>
          </div>

          <div className="hidden-sm hidden-xs">
            <ul className="nav navbar-nav navbar-right">
              <li>
                <Link to="About">About</Link>
              </li>
              <li>
                <Link to="SapSkills">SAP Skills</Link>
              </li>
              <li>
                <Link to="WebSkills">Web Skills</Link>
              </li>
              <li>
                <Link to="Projects">Projects</Link>
              </li>
              <li>
                <Link to="Sites">Sites</Link>
              </li>
              <li>
                <Link to="Games">Games</Link>
              </li>
              <li>
                <Link to="Timeline">Timeline</Link>
              </li>
              <li>
                <a
                  href="https://abaplaunchpad.com"
                  title="https://abaplaunchpad.com"
                  alt="abaplaunchpad"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blog
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
